import revive_payload_client_4sVQNw7RlN from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vue_inputmask_F37Fu9Upv1 from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/plugins/directives/vue-inputmask.ts";
import vue_inview_NOj2h2ioEM from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/plugins/directives/vue-inview.ts";
import crm_redirect_bJQgQLkYeL from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/plugins/crm-redirect.ts";
import redirect_handler_AEckPqr5CF from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/plugins/redirect-handler.ts";
import input_focus_4H0W6ClaTZ from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/plugins/input-focus.ts";
import subscribe_club_L2o7d7sGVs from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/plugins/subscribe-club.ts";
import login_r3gbI5eVa9 from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/plugins/login.ts";
import send_3S2slpJswS from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/plugins/send.ts";
import tracking_RJtqiU4YuV from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/plugins/tracking.ts";
import validate_fk94mvjWUp from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/plugins/validate.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vue_inputmask_F37Fu9Upv1,
  vue_inview_NOj2h2ioEM,
  crm_redirect_bJQgQLkYeL,
  redirect_handler_AEckPqr5CF,
  input_focus_4H0W6ClaTZ,
  subscribe_club_L2o7d7sGVs,
  login_r3gbI5eVa9,
  send_3S2slpJswS,
  tracking_RJtqiU4YuV,
  validate_fk94mvjWUp
]