<script setup lang="ts">
const props = defineProps({
  class: { type: String, required: false, default: undefined },
  size: { type: String, required: false, default: "18px" },
});
</script>

<template>
  <!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.class"
    :width="size"
    :height="size"
    viewBox="0 0 60 60"
    style="enable-background: new 0 0 60 60"
    xml:space="preserve"
    aria-hidden="true"
  >
    <g id="Raggruppa_8591" transform="translate(-5379 -5396.76)">
      <g id="Raggruppa_8560" transform="translate(5384.977 5410.941)">
        <path
          id="Tracciato_7982"
          class="st0"
          d="M47.2,0c0.3,0.2,0.4,0.5,0.4,0.8c0,2.6,0,5.1,0,7.7c0,1.1-0.8,2.1-1.9,2.3
			c-0.2,0-0.4,0.1-0.7,0.1v0.5c0,1.9,0,3.8,0,5.7c0,0.8-0.3,1.6-0.9,2.2c-1.9,1.9-3.8,3.8-5.7,5.7c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.7,0,1.4,0,2c0,1.1-0.6,2.1-1.5,2.6c-0.5,0.3-1.1,0.5-1.7,0.7c0.1,0.9-0.2,1.8-0.7,2.5c-0.6,0.8-1.5,1.2-2.5,1.3
			c0,0.2,0,0.4,0,0.6c0,1.7-1.2,3.1-2.8,3.3c-0.7,0.2-1.3-0.1-1.7-0.7V22.7c0.3-0.6,0.6-1.2,1-1.8c1.9-3.2,3.9-6.4,5.8-9.6
			c0.1-0.1,0.2-0.3,0.3-0.5c-0.2-0.1-0.3-0.1-0.4-0.1c-1-0.3-1.6-1.2-1.6-2.2c0-2.6,0-5.2,0-7.8c0-0.3,0.2-0.6,0.5-0.7H33
			c0.3,0.2,0.5,0.6,0.4,1c0,2.4,0,4.9,0,7.3c-0.1,0.6,0.3,1.2,0.9,1.3c0.1,0,0.2,0,0.3,0c0.7,0,1.4,0,2.1,0c0.3,0,0.7,0.2,0.7,0.6
			c0,0,0,0,0,0.1c0,0.4-0.3,0.7-0.6,0.7c0,0,0,0-0.1,0c-0.2,0-0.5,0-0.7,0c-0.3,0-0.5,0.1-0.6,0.4c-2.2,3.7-4.5,7.4-6.7,11.2
			c-0.2,0.3-0.3,0.7-0.3,1c0,4.5,0,9,0,13.5v0.5c1.1,0,1.9-0.9,1.9-2c0,0,0,0,0-0.1c0-2.8,0-5.6,0-8.4c0-0.7,0.2-1.3,0.7-1.7
			c2.5-2.4,4.9-4.9,7.4-7.4c0.2-0.2,0.4-0.5,0.9-0.4c0.1,0,0.2,0.1,0.2,0.1c0.3,0.2,0.3,0.6,0.1,0.9c0,0,0,0,0,0
			c-0.1,0.1-0.2,0.2-0.3,0.3L35.2,23c-0.6,0.5-0.8,1.4-0.6,2.2c0.1,0.4,0.3,0.8,0.5,1.2c0.1-0.1,0.2-0.1,0.2-0.2
			c2.7-2.7,5.3-5.3,8-8c0.2-0.3,0.4-0.6,0.4-1c0-1.9,0-3.9,0-5.8v-0.5h-0.5c-1.1,0-2.1,0-3.2,0c-0.3,0-0.5-0.2-0.6-0.4
			c-0.1-0.2-0.1-0.4,0.1-0.6c0.2-0.2,0.4-0.3,0.6-0.3c1.6,0,3.3,0,4.9,0c0.9,0,1.3-0.4,1.3-1.3c0-2.4,0-4.9,0-7.3
			c0-0.4,0.1-0.7,0.4-0.9L47.2,0z M33.6,31.6c0-1.8,0-3.5,0-5.3c-0.1-0.5-0.2-0.9-0.4-1.3c-0.5,0.4-0.9,0.9-1.4,1.3
			c-0.1,0.1-0.1,0.3-0.1,0.4c0,2.2,0,4.3,0,6.5c0,0.1,0,0.1,0,0.2C32.7,33.4,33.6,32.6,33.6,31.6L33.6,31.6z M34.9,27.9v1.6
			c1.3,0,2-1,1.9-2.9C36.1,27,35.8,27.9,34.9,27.9z"
        />
        <path
          id="Tracciato_7983"
          class="st0"
          d="M0.9,0c0.3,0.2,0.5,0.6,0.4,0.9c0,2.4,0,4.9,0,7.3c0,0.9,0.4,1.3,1.3,1.3
			c1.6,0,3.3,0,4.9,0c0.2,0,0.5,0.1,0.6,0.3c0.1,0.2,0.2,0.4,0.1,0.6c-0.2,0.2-0.4,0.4-0.6,0.4c-1.1,0-2.1,0-3.2,0H3.9v0.5
			c0,1.9,0,3.9,0,5.8c0,0.4,0.1,0.7,0.4,1c2.6,2.7,5.3,5.3,8,8c0,0,0.1,0.1,0.2,0.2c0.2-0.4,0.4-0.8,0.5-1.2c0.2-0.8,0-1.6-0.6-2.2
			l-4.2-4.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.2-0.6,0.1-0.9c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.1c0.5-0.1,0.6,0.1,0.9,0.4
			c2.5,2.5,4.9,4.9,7.4,7.4c0.5,0.5,0.7,1.1,0.7,1.7c0,2.8,0,5.6,0,8.4c0,1.1,0.8,2,1.9,2c0,0,0,0,0.1,0v-0.5c0-4.5,0-9,0-13.5
			c0-0.4-0.1-0.7-0.3-1c-2.2-3.7-4.5-7.4-6.7-11.2c-0.1-0.3-0.4-0.4-0.6-0.4c-0.2,0-0.5,0-0.7,0c-0.4,0-0.7-0.3-0.7-0.6
			c0,0,0,0,0-0.1c0-0.3,0.3-0.6,0.6-0.6c0,0,0,0,0.1,0c0.7,0,1.4,0,2.1,0c0.6,0.1,1.2-0.3,1.3-0.9c0-0.1,0-0.2,0-0.3
			c0-2.4,0-4.9,0-7.3c0-0.4,0.1-0.8,0.4-1h0.4c0.3,0.1,0.5,0.4,0.5,0.7c0,2.6,0,5.2,0,7.8c0,1-0.7,1.9-1.6,2.2
			c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.2,0.2,0.3,0.3,0.5c1.9,3.2,3.9,6.4,5.8,9.6c0.3,0.6,0.6,1.2,1,1.8V38c-0.4,0.6-1,0.8-1.7,0.7
			c-1.6-0.2-2.9-1.6-2.8-3.3c0-0.2,0-0.4,0-0.6c-1,0-1.9-0.5-2.5-1.3c-0.6-0.7-0.8-1.6-0.7-2.5c-0.6-0.2-1.2-0.4-1.7-0.7
			c-0.9-0.5-1.5-1.6-1.5-2.6c0-0.7,0-1.4,0-2c0-0.3-0.1-0.5-0.3-0.7c-1.9-1.9-3.8-3.8-5.7-5.7c-0.6-0.6-0.9-1.4-0.9-2.2
			c0-1.9,0-3.8,0-5.7v-0.5c-0.2,0-0.5-0.1-0.7-0.1C0.8,10.6,0,9.6,0,8.5c0-2.6,0-5.1,0-7.7C0,0.5,0.2,0.2,0.4,0L0.9,0z M15.9,33.4
			c0-0.1,0-0.1,0-0.2c0-2.2,0-4.3,0-6.5c0-0.1,0-0.3-0.1-0.4c-0.5-0.5-0.9-0.9-1.4-1.3c-0.2,0.4-0.3,0.9-0.4,1.3c0,1.8,0,3.5,0,5.3
			C14.1,32.6,14.9,33.4,15.9,33.4L15.9,33.4z M10.8,26.6c-0.1,1.9,0.5,2.9,1.9,2.9v-1.6C11.8,27.9,11.5,27,10.8,26.6z"
        />
        <path
          id="Rettangolo_1022"
          class="st0"
          d="M24.2,0L24.2,0c0.2,0,0.4,0.2,0.4,0.4v37.9c0,0.2-0.2,0.4-0.4,0.4h0
			c-0.2,0-0.4-0.2-0.4-0.4V0.4C23.8,0.2,24,0,24.2,0z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: #363636;
}
</style>
