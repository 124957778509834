import type { IData, IRate, IValue } from "~/interfaces";
import numeral from "numeral";
import { getThemeData } from "~/utils";
import { POWER_CREDIT_MIN_CREDIT } from "~/utils/constants";

// register locale
numeral.register("locale", "it", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  currency: {
    symbol: "€",
  },
});
numeral.locale("it");

/*
 * Get credit values from json
 */
export const getSelectedCreditValue = (): IValue => {
  const data: IData = getThemeData();
  return (
    data.values.credit.filter((val: IValue) => val.default)[0]?.value ??
    data.values.credit[0].value
  );
};

/*
 * Get credit values from json
 */
export const getCreditValues = (): IValue[] => {
  const data: IData = getThemeData();
  return data.values.credit ?? [];
};

/*
 * Get price values from json
 */
export const getPriceValues = (): IRate[] => {
  const data: IData = getThemeData();
  return data.values.prices ?? [];
};

/**
 * Formats a given number or numeric string based on the specified format.
 *
 * @param {number | string} value - The numeric value to be formatted. It can be either a number or a string that can be converted to a number.
 * @param {number | null} [format=null] - The format type. It determines how the number is formatted:
 *    1 - Comma-separated integers (e.g., 1,000)
 *    2 - Comma-separated with two decimal places (e.g., 1,000.00)
 *    3 - Comma-separated with three decimal places (e.g., 1,000.000)
 *    4 - Comma-separated with four decimal places (e.g., 1,000.0000)
 *    5 - Comma-separated integers with a euro symbol (e.g., 1,000 €)
 *    6 - Comma-separated with one decimal place (e.g., 1,000.0)
 *    7 - Comma-separated integers (e.g., 1,000)
 *    default - Comma-separated with two decimal places and a euro symbol (e.g., 1,000.00 €)
 *
 * @param symbol
 * @returns {string} - The formatted string.
 */
export const formatNumber = (
  value: number | string,
  format?: number | null,
  symbol: string = "€",
): string => {
  if (typeof value === "string") {
    value = Number(value);
  }

  let formattedValue: string;

  switch (format) {
    case 1:
      formattedValue = numeral(value).format("0,0");
      break;
    case 2:
      formattedValue = numeral(value).format("0,0.00");
      break;
    case 3:
      formattedValue = numeral(value).format("0,0.000");
      break;
    case 4:
      formattedValue = numeral(value).format("0,0.0000");
      break;
    case 5:
      formattedValue = numeral(value).format("0,0");
      break;
    case 6:
      formattedValue = numeral(value).format("0,0.0");
      break;
    case 7:
      formattedValue = numeral(value).format("0,0");
      break;
    default:
      formattedValue = numeral(value).format("0,0.00");
  }

  return symbol ? `${formattedValue} ${symbol}` : formattedValue;
};

/**
 * Formats price values in the provided IRate object.
 *
 * @param {IRate} val - The IRate object containing price values to format.
 * @returns {IRate} The IRate object with formatted price values.
 */
export const formatPriceValues = (val: IRate): IRate => {
  const data: IData = getThemeData();
  try {
    if (isNumber(val.imp_rata))
      val.imp_rata = numeral(val.imp_rata).format("0.00");
    if (isNumber(val.tot_rata))
      val.tot_rata = val.tot_rata
        ? numeral(val.tot_rata).format("0.00")
        : val.imp_rata;
    if (isNumber(val.club_rata))
      val.club_rata = val.club_rata ? numeral(val.club_rata).format("0.00") : 0;
    if (isNumber(val.insurance))
      val.insurance = val.insurance ? numeral(val.insurance).format("0.00") : 0;
    if (isNumber(val.tan)) val.tan = numeral(val.tan).format("0.000");
    if (isNumber(val.taeg)) val.taeg = numeral(val.taeg).format("0.00");
    if (isNumber(val.imp_finanz))
      val.imp_finanz_text = numeral(val.imp_finanz).format("0,0 $");
    if (isNumber(val.dovuto)) val.dovuto = numeral(val.dovuto).format("0,0.00");
    if (isNumber(val.imposta))
      val.imposta = numeral(val.imposta).format("0.00");
    if (!val.secci_url)
      val.secci_url = `${data.endpoints.secci}${val.imp_finanz}_${val.rate}.pdf`;
    return val;
  } catch (e) {
    return val;
  }
};

/**
 * Get model
 * @param value
 */
export const getModel = (value: string | number): IRate => {
  return getPriceValues()
    .sort((a: IRate, b: IRate) => b.rate - a.rate)
    .find((p: IRate) => p.imp_finanz === Number(value)) as IRate;
};

/**
 * Get offers function
 *
 * If only one it's true `getOffers` function return only one offer
 * @param onlyOne
 */
export const getOffers = (onlyOne: boolean = true): IValue | IRate[] => {
  const prices = getPriceValues();
  const selectedCredit: number = Number(getStateValue("selectedCredit"));
  //const filteredPrices: IRate[] = _.orderBy(prices, ["rate"], ["desc"]);
  const filteredPrices = prices
    .slice()
    .sort((a: IRate, b: IRate) => b.rate - a.rate);

  const ret: IRate[] = filteredPrices.reduce((red: IRate[], val: IRate) => {
    if (Number(val.imp_finanz) === selectedCredit) {
      if (onlyOne && red.length > 0) return red;
      red.push(formatPriceValues(val));
      if (onlyOne) return red; // Return early if onlyOne is true and we have found a match
    }
    return red;
  }, []);

  if (!onlyOne && ret.length > 0) ret.shift();

  return ret;
};


export const removeZeroDecimals = (rata: number): number => {
  const [int, decimals] = rata.toString().split(',')
  if (decimals && decimals === '00')
    return parseInt(int)
  return rata
}

export const isPowerMinCredit = (model: IRate): boolean => {
  return model.imp_finanz === POWER_CREDIT_MIN_CREDIT;
}