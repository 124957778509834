import type { IPackageInsurance } from "~/interfaces";

/**
 * Get insurance package type
 */
export const insurancePackage = (): IPackageInsurance => {
  const JOB = getStateValue("employmentType", 0);
  const HIRING = getStateValue("hiringType", 0);
  const OCCUPATION = getStateValue("occupation", 0);
  const RATA = getStateValue("rate", 0);
  const SECTOR = getStateValue("employmentSector", 0);

  if (RATA <= 24) return INSURANCE_PACKAGES.D as IPackageInsurance;

  if (JOB === EMPLOYMENT_TYPES.DIPENDENTE) {
    if (SECTOR === 4) {
      if (OCCUPATION !== 2 && HIRING === 2) {
        return INSURANCE_PACKAGES.G as IPackageInsurance;
      }
    }
    if (VALID_SECTORS_FOR_INSURANCE_PACKAGE.includes(SECTOR)) {
      if (
        (OCCUPATION !== 1 && HIRING === HIRING_TYPES.INDETERMINATO) ||
        OCCUPATION === 2
      ) {
        return INSURANCE_PACKAGES.G as IPackageInsurance;
      }
    }
    if (SECTOR === 8) {
      if (
        OCCUPATION === 4 ||
        (HIRING === HIRING_TYPES.INDETERMINATO && OCCUPATION !== 1)
      ) {
        return INSURANCE_PACKAGES.G as IPackageInsurance;
      }
    }
  }
  return INSURANCE_PACKAGES.E as IPackageInsurance;
};

export const insuranceRate = () => {
  const PACKAGE = insurancePackage();
  const MULTIPLIER = INSURANCE_MULTIPLIER[PACKAGE];
  const CREDIT = getStateValue("credit", 0);

  return (Math.ceil(parseFloat(CREDIT) * MULTIPLIER * 10) / 10).toFixed(2);
};

/**
 * Checks if insurance is available for the user.
 */
export const insuranceAvailable = (): boolean => {
  const AGE = userAge();
  const PATH = userPath();
  const RATA = getStateValue("rate", 0);
  const JOB = getStateValue("employmentType", 0);
  const IS_REVOLVING = getStateValue("isRevolving")

  if (PATH !== USER_PATHS.FE) return false;
  if (IS_REVOLVING) return false;
  if ([JOB_TYPES.CASALINGA, JOB_TYPES.DISOCCUPATO, JOB_TYPES.REDDITIERE].includes(JOB)) return false;

  return AGE + 1 + RATA / 12 <= AGE_LIMIT;
};

/**
 * Removes insurance from the user state.
 */
export const insuranceDecline = (): void => {
  updateStateValue("insurance", false);
  updateStateValue("consentMain", null);
  updateStateValue("consent1", null);
  updateStateValue("consent2", null);
  updateStateValue("consent3", null);
  updateStateValue("consent4", null);
  updateStateValue("consent5", null);
  updateStateValue("consent6", null);
  updateStateValue("consent7", null);
};

/**
 * Checks if MetLife insurance is available for the user.
 */
export const metlifeAvailable = () => {
  const INCOME = getStateValue("monthlyIncome", 0);
  const ADDRESS = getStateValue("addressCity", "");
  const AGE = userAge();
  const PATH = userPath();

  const PRV = /\(([^)]+)\)/.exec(ADDRESS);
  const ML = INCOME < 700 || (AGE < 50 && PRV && METLIFE_PRV.includes(PRV[1]));
  const CQ = PATH == USER_PATHS.CQ;

  return AGE >= 18 && AGE < 74 && (ML || CQ);
};

/**
 * Removes insurance from the user state.
 */
export const metlifeDecline = (): void => {
  updateStateValue("insurance", false);
  updateStateValue("metlifeConsent1", null);
  updateStateValue("metlifeConsent2", null);
  updateStateValue("metlifeConsent3", null);
  updateStateValue("metlifeConsent4", null);
  updateStateValue("metlifeConsent5", null);
  updateStateValue("metlifeConsent6", null);
  updateStateValue("metlifeConsent7", null);
  updateStateValue("metlifeConsent8", null);
};
