import { defineNuxtPlugin, navigateTo, NuxtApp } from "#app";

export default defineNuxtPlugin((nuxtApp: NuxtApp) => {
  window.nuxtNavigateTo = (url: string, targetBlank = false) => {
    if (!targetBlank) {
      navigateTo(url);
    } else {
      window.open(url, "_blank");
    }
  };
});
