import type {
  IMapping,
  ITextIcon,
  ITaxCodeObject,
  IData,
  Route,
} from "~/interfaces";
import CF from "codice-fiscale-js";
import { DateTime } from "luxon";
import data from "~/data.json";

/**
 * Get theme data
 */
export const getThemeData = (): IData => {
  return data;
};

/**
 * Generates a dynamic icon component based on the provided ITextIcon object.
 */
export const iconComponent = (el: ITextIcon) => {
  const component = defineAsyncComponent(
    () => import(`~/components/icons/${el.icon}.vue`),
  );
  return { icon: component, text: el.text };
};

/**
 * Inverts an object by swapping keys and values.
 * @param {Object} obj - The object to invert.
 */
export const invertObject = (obj: {
  [key: string]: any;
}): { [key: string]: string } => {
  return Object.keys(obj).reduce(
    (acc, key) => {
      const value = obj[key];
      acc[value] = key;
      return acc;
    },
    {} as { [key: string]: string },
  );
};

/**
 * Evaluates all functions in an object and returns the result.
 * @param obj
 */
export const evaluateObjectFunctions = (obj: any) => {
  return Object.keys(obj).reduce((acc: any, key: any) => {
    acc[key] = obj[key]();
    return acc;
  }, {});
};

/**
 * Check if provided value it's a number
 * @param value
 */
export const isNumber = (value: any): boolean => {
  return typeof value === "number";
};

/**
 * Check if provided value it's a boolean
 * @param value
 */
export const isBool = (value: any): boolean => {
  return typeof value === "boolean";
};

/**
 *  Removes all empty values from an object.
 * @param obj
 */
export const removeEmptyValues = (obj: any) => {
  return Object.keys(obj).reduce((acc: any, key: any) => {
    if (obj[key] !== null && obj[key] !== undefined) acc[key] = obj[key];
    return acc;
  }, {});
};

/**
 * Shows the error modal.
 */
export const showModalError = () => {
  setStateValue("errorModal", true);
};

/**
 * Scroll to top
 */
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

/**
 * Validate a form submit step.
 * @param mapping
 */
export const validateStep = (mapping: IMapping): boolean => {
  const isValid = useNuxtApp().$validate(mapping)?.passes() || false;
  if (!isValid) {
    showModalError();
  }
  return !!isValid;
};

/**
 * Retrieve extracted fiscal code from state
 */
export const userTaxCode = (): ITaxCodeObject => {
  const taxCode = getStateValue("taxCode", "");
  try {
    const data = CF.computeInverse(taxCode) as ITaxCodeObject;
    // legacy adjustments
    data.country =
      data.birthplaceProvincia === "EE" ? data.birthplace : "ITALIA";
    data.city = data.birthplaceProvincia === "EE" ? "EE" : data.birthplace;
    return data;
  } catch (e) {
    const data = TAX_CODE;
    data.cf = taxCode;
    return data;
  }
};

/**
 *  Retrieve the user's dob based on the extraction of their fiscal code.
 */
export const userDOB = (): DateTime => {
  const { year, month, day } = userTaxCode();
  const doubleDigits = (value: number) =>
    Number(value) < 10 ? `0${value}` : value;
  return DateTime.fromFormat(
    `${year}-${doubleDigits(month ?? 1)}-${doubleDigits(day ?? 1)}`,
    "yyyy-MM-dd",
  );
};

/**
 *  Retrieve the user's age based on the extraction of their fiscal code.
 */
export const userAge = (): number => {
  return Math.floor(DateTime.local().diff(userDOB(), "years").years);
};

/**
 * Set state value and return ref
 * @param key
 * @param value
 */
export const setStateValue = (key: string, value: any) => {
  return useState<any>(key, () => value);
};

/**
 * Update state value
 * @param key
 * @param value
 */
export const updateStateValue = (key: string, value: any) => {
  useState<any>(key).value = value;
};

/**
 * Get state value
 * @param key
 * @param val
 */
export const getStateValue = (key: string, defaultValue: any = undefined) => {
  const value = useState<any>(key).value;
  return isBool(value) ? value : value || defaultValue;
};

/**
 * Log navigation steps
 * @param key
 * @param value
 */
export const logSteps = (step: string) => {
  const log = getStateValue("logSteps", []);
  log.push(step);
  updateStateValue("logSteps", log);
};

/**
 * Load dev data for testing purposes
 * @param data
 */
export const loadDevValues = (data: object) => {
  Object.keys(data).forEach((key, index) => {
    setStateValue(key, data[key as keyof typeof data]);
  });
};

/**
 * Get environment type
 */
export const env = () => {
  return process.env.NODE_ENV;
};

/**
 * Get dev environment
 */
export const isDev = () => {
  return env() === "development";
};

/**
 * Determine is WhatsApp customer service is available, according to timetables
 */
export const isWaAvailable = () => {
  const START_TIME = DateTime.now()
    .setZone("Europe/Rome")
    .set({ hour: 9, minute: 30 });
  const END_TIME = DateTime.now()
    .setZone("Europe/Rome")
    .set({ hour: 13, minute: 0 });
  const FIRST_DAY = 1;
  const LAST_DAY = 5;
  const now = DateTime.now().setZone("Europe/Rome");

  return (
    now > START_TIME &&
    now < END_TIME &&
    now.weekday >= FIRST_DAY &&
    now.weekday <= LAST_DAY
  );
};

/**
 * Append "a" tag & redirect in a new tag
 * @param url
 */
export const fallbackRedirect = (url: string) => {
  const a = document.createElement("a");
  a.classList.add("hidden");
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");
  a.click();
};

/**
 * Parse JSON and replace <a> tags with onClick event
 * @param json
 */
export function parseJsonUrls(json: any) {
  const parsedJson = JSON.stringify(json, null, 2).replace(/\\"/g, "'");

  // Regex to match anchor tags with href attributes
  const regex = /<a\b[^>]*?href='([^'">]*)'[^>]*?>.*?<\/a>/g;

  const replacedJSON = parsedJson.replace(regex, (match, href) => {
    const isAbsoluteUrl =
      href.startsWith("http://") || href.startsWith("https://");

    if (isAbsoluteUrl) {
      // Check if the target attribute exists and includes '_blank'
      const targetMatch = /target='([^'<>]*)'/g.exec(match);
      const hasTargetBlank = targetMatch && targetMatch[1] === "_blank";

      if (!hasTargetBlank) {
        // Add or modify the target attribute to ensure _blank
        return match.replace(/<a\b([^>]*?)>/, `<a$1 target='_blank'>`);
      }
    } else {
      // Handle relative URLs
      const urlMatch = /href='([^'<>]*)'/g.exec(match);
      if (urlMatch) {
        const cleanedUrl = urlMatch[1];
        const targetMatch = /target='([^'<>]*)'/g.exec(match);
        const isBlank = targetMatch && targetMatch[1] === "_blank";

        return match.replace(
          urlMatch[0],
          `href=\\"javascript:void(0)\\" onclick=\\"window.nuxtNavigateTo('${cleanedUrl}', ${isBlank})\\"`,
        );
      }
    }

    return match; // Return unchanged if no conditions are met
  });

  return JSON.parse(replacedJSON);
}

/**
 * Get locale
 *
 * Replace <a> tags attributes
 *
 * @param localeFile
 * @param key
 * @param lang
 */
export async function getLocale(
  localeFile: string,
  key?: string,
  lang: string = "it",
) {
  const json = await import(`~/static/locale/${lang}/${localeFile}.json`);
  const parsedJSON = parseJsonUrls(json);
  return key ? parsedJSON[key] : parsedJSON;
}

/*
 * Get the base URL
 */
export function baseUrl() {
  return window.baseUrl ? window.baseUrl.split("?")[0] : "/";
}

/**
 *
 * @param routes
 * @param step
 * @param defaultRoute
 */
export function getRoute(
  routes: Route[],
  step: string,
  defaultRoute: string = "",
): Route {
  return (
    routes.find((route) => route.path === step) ??
    routes.find((route) => route.name === defaultRoute) ??
    routes[0]
  );
}
