import { DateTime } from "luxon";

/**
 * Checks if the user qualifies for a Revolving program based on credit amount and path.
 */
export const isRevolving = (): boolean => {
  return getStateValue('isRevolving');
};

/**
 * Checks if the user qualifies for a Feevo program based on credit amount and path.
 */
export const isFeevo = () => {
  const PATH = userPath();
  return PATH == USER_PATHS.FE && !isRevolving();
};

/**
 * Get user string path
 */
export const userPath = (): string => {
  const monthlyIncome = getStateValue("monthlyIncome");
  const employmentType = getStateValue("employmentType");
  const hiringType = getStateValue("hiringType");
  const employedSince = getStateValue("employedSince", "");
  const employedTo = getStateValue("employedTo", "");
  const financialProblems = getStateValue("financialProblems");
  const choosenRata = getStateValue("rate");
  const isRevolving = getStateValue("isRevolving");
  const familyIncome = getStateValue("familyIncome");

  const AGE = userAge();
  const NOW = DateTime.now();
  const REDDITO = monthlyIncome
    ? parseInt(monthlyIncome.replace(/\D/g, ""))
    : 0;
  const IS = (arr: number[]) => arr.includes(employmentType);

  const DIPENDENTE_DETERMINATO = [JOB_TYPES.DIPENDENTE].includes(employmentType) && hiringType == HIRING_TYPES.DETERMINATO;
  const DIPENDENTE_INDETERMINATO = [JOB_TYPES.DIPENDENTE].includes(employmentType) && hiringType == HIRING_TYPES.INDETERMINATO;
  const DIPENDENTE_DETERMINATO_FEEVO_PP = NOW > DateTime.fromFormat(employedSince, "MM/yyyy").plus({ months: 3 }) && NOW.plus({ months: choosenRata }) <= DateTime.fromFormat(employedTo, "mm/yyyy");
  const PROFESSIONISTA_FEEVO_PC = NOW > DateTime.fromFormat(employedSince, "MM/yyyy").plus({ months: 12 });
  const PROFESSIONISTA_FEEVO_PP = NOW > DateTime.fromFormat(employedSince, "MM/yyyy").plus({ months: 18 });
  const DIPENDENTE_INDETERMINATO_FEEVO_PC = NOW > DateTime.fromFormat(employedSince, "MM/yyyy").plus({ months: 6 });
  const DIPENDENTE_INDETERMINATO_FEEVO_PP = NOW > DateTime.fromFormat(employedSince, "MM/yyyy").plus({ months: 6 });
  const AUTONOMO_FEEVO_PC = NOW > DateTime.fromFormat(employedSince, "MM/yyyy").plus({ months: 18 }); //moment() > moment(userData.employed_since, 'MM/YYYY').add(18, 'months');
  const CREDIT_RISK = financialProblems || (isRevolving && AGE > AGE_LIMIT) || (!isRevolving && AGE + choosenRata / 12 > 80);

  //Redirect for CREDIT_RISK && FAMILY_INCOME
  const FAMILY_INCOME = () => {
    const familyIncome = getStateValue("familyIncome");
    if (familyIncome === undefined) return USER_PATHS.FAM;
    return familyIncome ? USER_PATHS.CQ : USER_PATHS.ML;
  };
  // PrestitoPersonale + PowerCredit (with financial problems | overaged) path
  if (CREDIT_RISK) {
    if (
      IS([JOB_TYPES.PENSIONATO, JOB_TYPES.DIPENDENTE]) &&
      REDDITO > REDDITO_LIMIT
    )
      return USER_PATHS.CQ;
    else if (
      IS([
        JOB_TYPES.PENSIONATO_INVALIDO,
        JOB_TYPES.STAGIONALE,
        JOB_TYPES.AUTONOMO,
        JOB_TYPES.REDDITIERE,
        JOB_TYPES.PROFESSIONISTA,
      ]) &&
      REDDITO > REDDITO_LIMIT
    )
      return FAMILY_INCOME();
    // PrestitoPersonale path
  } else if (!isRevolving) {
    if ((DIPENDENTE_INDETERMINATO && DIPENDENTE_INDETERMINATO_FEEVO_PP && REDDITO > REDDITO_LIMIT) || (DIPENDENTE_DETERMINATO && DIPENDENTE_DETERMINATO_FEEVO_PP && REDDITO > REDDITO_LIMIT)) return USER_PATHS.FE;
    else if (IS([JOB_TYPES.PENSIONATO, JOB_TYPES.REDDITIERE]) && REDDITO > REDDITO_LIMIT) return USER_PATHS.FE;
    else if (IS([JOB_TYPES.AUTONOMO, JOB_TYPES.PROFESSIONISTA]) && REDDITO > REDDITO_LIMIT && PROFESSIONISTA_FEEVO_PP) return USER_PATHS.FE;
    else if (IS([JOB_TYPES.PENSIONATO_INVALIDO, JOB_TYPES.STAGIONALE]) && REDDITO > REDDITO_LIMIT) return FAMILY_INCOME();
    // PC (Revolving) path
  } else {
    if (IS([JOB_TYPES.AUTONOMO]) && AUTONOMO_FEEVO_PC) return USER_PATHS.FE;
    else if (
      IS([JOB_TYPES.PENSIONATO, JOB_TYPES.REDDITIERE]) &&
      REDDITO > REDDITO_LIMIT
    )
      return USER_PATHS.FE;
    else if (
      IS([JOB_TYPES.PENSIONATO_INVALIDO, JOB_TYPES.STAGIONALE]) &&
      REDDITO > REDDITO_LIMIT
    )
      return FAMILY_INCOME();
    else if (
      IS([JOB_TYPES.PROFESSIONISTA]) &&
      PROFESSIONISTA_FEEVO_PC &&
      REDDITO > REDDITO_LIMIT
    )
      return USER_PATHS.FE;
    else if (
      DIPENDENTE_INDETERMINATO &&
      DIPENDENTE_INDETERMINATO_FEEVO_PC &&
      REDDITO > REDDITO_LIMIT
    )
      return USER_PATHS.FE;
  }
  return USER_PATHS.ML;
};
