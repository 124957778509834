import IBAN from "iban";
import CF from "codice-fiscale-js";

import { helpers } from "@vuelidate/validators";
import { DateTime } from "luxon";

import type { BaseValidation } from "@vuelidate/core";
import { drivingLicenseMask, passportMask } from "~/utils/masks";

class Validator {
  constructor() {}

  message(v$: BaseValidation): string {
    return v$.$errors[0] ? (v$.$errors[0].$message as string) : "";
  }

  /**
   * Retrieve the error message for validate collections
   * @param v$
   * @param key
   * @param index
   */
  eachMessage(v$: BaseValidation, key: string, index: number | string): string {
    return (
      v$.$each.$response.$errors?.[index as number]?.[key]?.[0]?.$message ?? ""
    );
  }

  /**
   * Retrieve the boolean value for validate collections
   * @param v$
   * @param key
   * @param index
   */
  eachHasError(v$: BaseValidation, key: string, index: number | string) {
    return (
      v$.$each.$response.$data[index as number]?.[key]?.$error && v$.$error
    );
  }

  nonZeroIf(locator: string) {
    return (field: any, vm: any) => {
      if (!vm[locator].value) return true;
      return parseInt(field ?? 0) !== 0;
    };
  }

  nonZero(field: any) {
    return parseInt(field ?? 0) !== 0;
  }

  mustBeTrue(value: any) {
    return !helpers.req(value) || value === true;
  }

  cellphone(value: string) {
    const re = /^\+39 \d{3}\s?\d{7}$/;
    return re.test(value);
  }

  phone(value: string) {
    const re = /^(\+39[. ]??)??[3|0]\d{5,9}$/;
    return re.test(value);
  }

  taxCode(code: any) {
    return !helpers.req(code) || CF.check(code);
  }

  taxCodeName(code: any, { name, surname }: any) {
    const cf_data = CF.computeInverse(code);
    // Add exception for wrong code Z254
    if (cf_data.birthplace == "GEORGIA") cf_data.birthplace = "SERBIA";
    if (cf_data.birthplace == "KAZAKISTAN") cf_data.birthplace = "SERBIA";

    const cf = CF.compute({
      name: name.value,
      surname: surname.value,
      gender: cf_data.gender,
      day: cf_data.day,
      month: cf_data.month,
      year: cf_data.year,
      birthplace: cf_data.birthplace,
      birthplaceProvincia: cf_data.birthplaceProvincia,
    });

    return cf.substr(0, 6) === code.substr(0, 6);
  }

  taxCodeAge(code: any) {
    const cf_data = CF.computeInverse(code);
    const birthDate = DateTime.fromObject({
      year: cf_data.year,
      month: cf_data.month,
      day: cf_data.day,
    });
    const years = DateTime.now().diff(birthDate, "years").years;

    return years >= 18;
  }

  documentNumber(number: string, { documentCity, identityType }: any) {
    if (!helpers.req(number)) return true;
    // Identity card
    if (identityType.value == DOCS.IDENTITY_CARD) {
      const regex = RegExp(identityCardMask.regex);
      return regex.test(number);
    }
    // Passport
    if (identityType.value == DOCS.PASSPORT) {
      const regex = RegExp(passportMask.regex);
      return regex.test(number);
    }
    // Driving license
    if (identityType.value == DOCS.DRIVERS_LICENSE) {
      const regex = RegExp(drivingLicenseMask.regex);
      if (regex.test(number)) {
        const matched = documentCity.value.match(/\([A-Z][A-Z]\)$/);
        const province =
          matched && matched.length ? matched[0].slice(1, 3) : null;
        if (!province) return true;
        return !(
          number.substr(0, 2) !== "U1" && number.substr(0, 2) != province
        );
      }
      return false;
    }
    return true;
  }

  validIban(value: string) {
    // remove spaces
    value = value.replace(/ +/g, "");
    const themeData = getThemeData();

    const isOurs = themeData.ourIbans?.includes(value) ?? false;
    const validLen =
      IBAN_LEN[value.substring(0, 2) as keyof typeof IBAN_LEN] === value.length;

    return IBAN.isValid(value) && !value.includes("_") && !isOurs && validLen;
  }

  notPrepaidIban(value: string) {
    if (!value) return false;

    const themeData = getThemeData();
    const ABI = value.replace(/ +/g, "").substring(5, 10);
    const CAB = value.replace(/ +/g, "").substring(10, 15);
    const CC = value.replace(/ +/g, "").slice(15, 27);

    // test if iban match prepaid iban cards
    const isPrepaid = themeData.prepaidIbans?.map((iban) => {
      iban.ABI = iban.ABI.toString();
      iban.CAB = iban.CAB.toString();
      iban.CC = iban.CC.toString();

      if( !getStateValue('isRevolving') && (!iban.Consumo || !iban.Carte))
        return false;
      if (iban.CC && iban.CAB && iban.ABI)
        return (
          iban.ABI == ABI &&
          iban.CAB == CAB &&
          iban.CC == CC.substring(0, iban.CC.length)
        );
      if (iban.ABI && iban.CAB) return iban.ABI == ABI && iban.CAB == CAB;
      if (iban.ABI && iban.CC)
        return iban.ABI == ABI && iban.CC == CC.substring(0, iban.CC.length);

      return false;
    });
    return !(isPrepaid ?? []).includes(true);
  }
}

const validator = Object.freeze(new Validator());
export default validator;
