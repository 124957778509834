import {
  evaluateObjectFunctions,
  getThemeData,
  removeEmptyValues,
} from "~/utils";
import type { IData } from "~/interfaces";

export default defineNuxtPlugin(() => {
  return {
    provide: {
      send: async ({ payload, method = "PUT" }: any) => {
        const body = evaluateObjectFunctions(payload);
        const data: IData = getThemeData();
        body.orderID = getStateValue("orderID");
        body.envType = getStateValue("envType");
        body.envName = getStateValue("envName");
        body.query_string = getStateValue("urlVars");

        //Verify if is necessary to remove empty values from the object
        const objWithoutNulls = removeEmptyValues(body);
        await $fetch(data.endpoints.server as string, {
          method,
          body: objWithoutNulls,
        });
      },
    },
  };
});
