<script setup lang="ts">
const props = defineProps({
  class: { type: String, required: false, default: undefined },
  size: { type: String, required: false, default: "18px" },
});
</script>

<template>
  <!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.class"
    :width="size"
    :height="size"
    viewBox="0 0 60 60"
    style="enable-background: new 0 0 60 60"
    xml:space="preserve"
    aria-hidden="true"
  >
    <g id="Raggruppa_8589" transform="translate(-717 -5361.352)">
      <g id="Raggruppa_8569" transform="translate(726.24 5371.19)">
        <g id="Raggruppa_8567" transform="translate(15.543 0)">
          <path
            id="Tracciato_7990"
            class="st0"
            d="M0,3V2.6c0,0,0-0.1,0-0.1c0.1-0.8,0.4-1.5,1.1-2C1.7,0,2.5-0.1,3.2,0.1
				c0.5,0.2,1,0.5,1.3,1c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0-0.1,0.1-0.1C5.1,1.2,5.2,1,5.4,0.8C6,0.1,7-0.2,7.9,0.1
				c0.8,0.3,1.5,1,1.7,1.9c0.2,0.9,0.1,1.9-0.5,2.6C8.8,5.1,8.4,5.6,7.9,6C7,6.8,6,7.6,5.1,8.5C5,8.6,4.8,8.6,4.6,8.5c0,0,0,0,0,0
				c-0.8-0.6-1.5-1.3-2.3-2C1.8,6,1.2,5.5,0.8,4.9C0.5,4.5,0.2,4,0.1,3.6C0.1,3.4,0,3.2,0,3z"
          />
        </g>
        <g id="Raggruppa_8568" transform="translate(0 2.559)">
          <path
            id="Tracciato_7991"
            class="st0"
            d="M23.8,32.4c0.2-0.3,0.3-0.3,0.3-0.6l1.3-1.2c0.2,0.9-0.1,1.8-0.6,2.6
				c0.3,0.3,0.6,0.6,0.9,0.9c1,1.3,0.9,3.1-0.3,4.2c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0c-1.3-1.1-1.9-1.7-3.2-2.8l1-0.8
				c1.1,1,1.4,1.2,2.5,2.1l0.3,0.3c0.7-0.7,0.6-1.9-0.1-2.5c0,0,0,0-0.1-0.1l-1.7-1.4L23.8,32.4z M21.3,31.8l-3.1-2.6
				c-0.4-0.4-0.7-0.9-0.7-1.4c-0.3-3.4-0.5-6.8-0.8-10.2c0-0.5,0.2-0.8,0.6-0.8c0.4,0,0.6,0.2,0.7,0.7c0.1,1.8,0.3,3.7,0.4,5.5
				c-0.1,1.2,0.8,2.2,1.9,2.3l0.5,0.1c0-0.2,0-0.3,0-0.4c-0.3-3.5-0.5-7-0.8-10.5c0-0.4-0.2-0.8-0.6-1.1c-1.4-1.2-2.8-2.4-4.2-3.6
				l-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-1.5,1.7-3,3.5-4.5,5.2C10,15.2,10,15.5,10,15.7c1.3,4,2.6,8,3.9,11.9
				c0.1,0.3,0.3,0.6,0.5,0.8l2.5,2.1l2.2,1.7l-0.9,1.1c-1.5-1.2-3.4-2.9-4.8-4.2c-0.3-0.3-0.6-0.7-0.7-1.2c-1.2-3.7-2.4-7.3-3.6-11
				C9.1,16.9,9,16.7,9,16.5c-0.1,0-0.1,0-0.1,0c-0.9,0.7-2.3,0.7-3.1-0.2c-1.8-1.5-3.6-3.1-5.4-4.6c-0.3-0.2-0.5-0.6-0.4-0.9
				l0.3-0.3c0.3-0.1,0.7,0.1,0.9,0.3c1.7,1.5,3.5,3,5.3,4.5C7.3,16,7.9,15.9,8.6,15c1.8-2.4,3.8-4.8,6-6.9c0.8-0.8,0.8-1.4,0.1-2.1
				c-1.8-1.5-3.5-3-5.3-4.5C9,1.2,8.9,0.9,8.9,0.5l0.3-0.3c0.3-0.1,0.7,0,0.9,0.2c1.7,1.5,3.4,3,5.1,4.4c1.2,0.8,1.5,2.3,0.7,3.5
				c-0.1,0.1-0.1,0.2-0.2,0.3l0.3,0.3c1.4,1.2,2.7,2.4,4.1,3.5c0.7,0.5,1.1,1.3,1.1,2.2c0.2,2.3-0.6,4.8-0.4,7.2
				c0,0.4-0.3,2.3-0.3,2.8l0.9,2.2l2.5,1.8l-0.9,0.9L22.7,29c-0.8-0.7-1.6-1.4-2.4-2c-0.5-0.4-1.1-0.7-1.7-1
				c0.1,0.7,0.1,1.3,0.2,1.9c0.1,0.2,0.2,0.4,0.3,0.5l2.4,2l0.4,0.3l0.3,0.3L21.3,31.8z"
          />
          <path
            id="Tracciato_7992"
            class="st0"
            d="M21.4,31.9c-1.1,1-2.2,2-3.2,3c-0.8,0.7-0.9,1.8-0.2,2.6c0,0,0,0,0,0l0.3-0.3
				c1.8-1.6,3.5-3.3,5.3-4.9l1.6-1.5l2.5-2.4c0.3-0.2,0.4-0.5,0.5-0.9c1.2-4,2.3-8,3.4-12c0.1-0.2,0-0.5-0.2-0.7
				c-0.2-0.1-0.3-0.3-0.5-0.5c-0.2-0.2-0.3-0.6,0-0.9c0,0,0,0,0.1,0c0.2-0.2,0.6-0.2,0.9,0c0,0,0,0,0,0c0.5,0.5,0.9,1,1.4,1.5
				c0.3,0.5,1,0.6,1.5,0.3c0.1-0.1,0.2-0.1,0.2-0.2c1.7-1.6,3.4-3.2,5.1-4.8c0.2-0.3,0.6-0.4,1-0.3l0.3,0.3c0.1,0.3,0,0.6-0.2,0.8
				c-1.8,1.7-3.7,3.4-5.5,5.1c-0.7,0.6-1.8,0.8-2.6,0.3c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1,0.2-0.1,0.3-0.2,0.5c-1,3.4-2,6.9-3,10.3
				c-0.2,0.6-0.4,1.2-0.6,1.8l-10.7,10c-0.6,0.1-1.3-0.2-1.6-0.8c-0.9-1.3-0.7-3.1,0.5-4.1c0.1-0.1,0.3-0.2,0.4-0.4
				c-0.6-0.7-0.9-1.7-0.8-2.6l1.7,1.7c0,0,0.1-0.1,0.1-0.1l1.7-1.6l2.9-2.7c0.1-0.1,0.2-0.2,0.2-0.3c0-0.6,0-1.2,0-1.9
				c-0.3,0.1-0.6,0.2-0.9,0.4c-0.2,0.1-0.5,0.2-0.8,0.2l0,0c-0.9,0-1.7-0.7-1.8-1.7c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
				c0-0.2,0-0.4,0-0.5l0.1-1.9c0.1-2.6,0.2-5.2,0.3-7.7c0-0.8,0.3-1.5,0.9-2.1c1.3-1.2,2.7-2.5,4-3.7l0.3-0.3
				c-0.1-0.2-0.3-0.4-0.4-0.6c-0.6-0.9-0.4-2.1,0.4-2.9c1.8-1.7,3.6-3.4,5.4-5C31.4,0,31.7-0.1,32,0l0.3,0.3
				c0.1,0.3-0.1,0.7-0.4,0.9c-1.7,1.6-3.4,3.2-5.2,4.8c-0.5,0.3-0.7,1-0.3,1.5c0.1,0.1,0.2,0.2,0.3,0.3c1.1,1.2,2.2,2.3,3.2,3.5
				c0.2,0.2,0.2,0.4,0.2,0.6c0,0.2-0.2,0.4-0.4,0.5c-0.3,0-0.5,0-0.7-0.2c-0.7-0.7-1.4-1.5-2.1-2.2l-0.3-0.4l-0.3,0.3
				c-1.4,1.3-2.7,2.5-4.1,3.8c-0.3,0.2-0.4,0.6-0.5,0.9c-0.2,3.6-0.3,7.2-0.4,10.8l0,0c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0.1,0
				c0.3-0.1,0.7-0.2,1-0.4c0.7-0.3,1.1-1.1,1.1-1.8c0.1-1.9,0.1-3.8,0.2-5.7c0-0.1,0-0.3,0-0.4c0-0.3,0.3-0.6,0.6-0.5c0,0,0,0,0,0
				c0.1,0,0.2,0,0.2,0.1c0.4,0.2,0.3,0.5,0.3,0.8c-0.1,3.3-0.2,6.7-0.4,10c0,0.6-0.3,1.2-0.8,1.7L21.4,31.9z"
          />
          <path
            id="Tracciato_7993"
            class="st0"
            d="M28.2,28.1c0,0.9-0.3,1.7-0.9,2.3c0.2,0.2,0.4,0.4,0.6,0.7c0.7,1,0.3,2.6-0.8,3.7
				c-0.4,0.4-0.8,0.5-1.1,0.2c-0.9-0.8-1.4-1.2-2.3-2l1-0.8c0.8,0.7,1,0.8,1.8,1.5l0.2,0.2c0.7-0.6,0.8-1.6,0.2-2.3l-1.2-1l1-0.8
				c0.2-0.3,0.3-0.3,0.4-0.6"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: #363636;
}
</style>
