<script setup lang="ts">
const props = defineProps({
  class: { type: String, required: false, default: undefined },
  size: { type: String, required: false, default: "18px" },
});
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="props.class"
    :width="props.size"
    :height="props.size"
    viewBox="0 0 90 90"
    xml:space="preserve"
    aria-hidden="true"
  >
    <g id="Raggruppa_8307" transform="translate(6340 -436)">
      <g id="Raggruppa_1865" transform="translate(-6314.289 457.651)">
        <path
          id="Tracciato_1057"
          class="st0"
          d="M0,23.5C0,16.4,0,9.3,0,2.3C-0.1,1.2,0.7,0.2,1.8,0c0.2,0,0.3,0,0.5,0
			c9.4,0,18.8,0,28.3,0c1.3,0,2.6,0,3.9,0c2.2-0.1,4.1,1.6,4.2,3.8c0,0.1,0,0.2,0,0.3c0,5.7,0,11.5,0,17.2c0,7.1,0,14.2,0,21.3
			c0.1,2-1.3,3.8-3.4,4.2C35,47,34.7,47,34.4,47C23.7,47,13,47,2.3,47C1.1,47,0,46.1,0,44.9c0-0.1,0-0.1,0-0.2C0,37.6,0,30.5,0,23.5
			z M1.4,23.5c0,7.1,0,14.1,0,21.2c0,0.7,0.2,0.9,0.8,0.9c9.5,0,18.9,0,28.4,0c0.2,0,0.4,0,0.5-0.1c1.3-0.3,2.2-1.5,2.1-2.8
			c0-7.3,0-14.6,0-21.9c0-5.6,0-11.1,0-16.7c0.1-1.4-1-2.7-2.4-2.8c-0.1,0-0.3,0-0.4,0c-9.3,0-18.6,0-28,0c-0.9,0-1.1,0.2-1.1,1.1
			C1.4,9.5,1.4,16.5,1.4,23.5L1.4,23.5z M33.7,1.3l-0.1,0.1c0.7,0.8,1,1.9,1,2.9c0,12.8,0,25.5,0,38.3c0,0.8-0.1,1.6-0.5,2.3
			c-0.1,0.2-0.3,0.4-0.5,0.7c0.5,0,1.1,0,1.6-0.1c1.3-0.3,2.2-1.5,2-2.8c0-11.4,0-22.8,0-34.2c0-1.5,0-3,0-4.5
			c0-1.2-0.8-2.3-2.1-2.6C34.7,1.4,34.2,1.3,33.7,1.3z"
        />
        <path
          id="Tracciato_1058"
          class="st0"
          d="M6,18.2C6,12,11.1,6.9,17.3,6.9c6.2,0,11.3,5.1,11.3,11.3c0,6.2-5.1,11.3-11.3,11.3
			C11.1,29.6,6,24.6,6,18.4C6,18.3,6,18.3,6,18.2z M16.6,13.2c-1,0-2,0-2.9,0c-0.2,0-0.5,0.1-0.5,0.4c0,0,0,0,0,0.1
			c-0.1,0.7-0.3,1.4-0.4,2c-0.1,0.7-0.1,1.4-0.2,2.1h4L16.6,13.2z M18,13.2v4.5h4c0-1.4-0.2-2.9-0.6-4.3c0-0.1-0.1-0.2-0.2-0.2
			C20.1,13.2,19.1,13.2,18,13.2z M18,23.5c0.1,0,0.1,0,0.1,0c0.6,0,1.3,0,1.9,0c1.3,0,1.3,0,1.5-1.2c0.2-1.1,0.3-2.2,0.5-3.3H18
			L18,23.5z M13.2,23.6h3.4v-4.4h-4.1C12.7,20.7,13,22.1,13.2,23.6L13.2,23.6z M22.8,23.6c0.9,0,1.8,0,2.7,0c0.1,0,0.2-0.1,0.3-0.2
			c0.8-1.3,1.3-2.9,1.4-4.4h-3.7L22.8,23.6z M7.4,17.7c1.2,0,2.4,0,3.6,0c0.1,0,0.2-0.2,0.2-0.3c0.1-0.4,0-0.8,0.1-1.3
			c0.1-1,0.3-2,0.5-3c-0.9,0-1.9,0-2.8,0c-0.1,0-0.2,0.1-0.3,0.2C7.9,14.7,7.4,16.2,7.4,17.7z M11.8,23.6l-0.7-4.5H7.4
			c0.1,1.5,0.6,3,1.4,4.3c0.1,0.1,0.2,0.2,0.3,0.2C10,23.6,10.9,23.6,11.8,23.6L11.8,23.6z M22.9,13.2c0.2,1.5,0.4,3,0.6,4.4h3.8
			c-0.1-1.5-0.5-2.9-1.2-4.2c-0.1-0.1-0.2-0.2-0.4-0.2C24.7,13.2,23.8,13.2,22.9,13.2L22.9,13.2z M18,8.4v3.4h2.9
			C20.4,10.3,19.4,9.1,18,8.4L18,8.4z M16.6,8.5c-1,0.2-2.6,2-2.8,3.3h2.8V8.5z M18,25V28c1.3-0.6,2.3-1.7,2.7-3.1H18z M16.6,25
			h-2.7c0.4,1.4,1.7,2.8,2.7,3V25z M24.9,11.8C23.8,10.6,22.5,9.6,21,9c0.4,0.9,0.9,1.7,1.3,2.6c0.1,0.1,0.2,0.2,0.3,0.2
			C23.3,11.8,24,11.8,24.9,11.8L24.9,11.8z M9.7,11.8c0.9,0,1.6,0,2.3,0c0.1,0,0.3-0.2,0.3-0.3c0.3-0.5,0.5-1,0.7-1.5
			c0.2-0.4,0.4-0.7,0.7-1.1C12.2,9.6,10.8,10.5,9.7,11.8L9.7,11.8z M24.7,24.9c-0.8,0-1.5,0-2.2,0c-0.1,0-0.2,0.1-0.3,0.2
			c-0.4,0.8-0.8,1.6-1.2,2.4C22.4,26.9,23.6,26.1,24.7,24.9L24.7,24.9z M9.9,24.9c1,1.1,2.3,2,3.7,2.6c-0.4-0.8-0.8-1.6-1.2-2.3
			c-0.1-0.1-0.2-0.2-0.3-0.2C11.5,24.9,10.8,24.9,9.9,24.9L9.9,24.9z"
        />
        <path
          id="Tracciato_1059"
          class="st0"
          d="M17.3,35.6H6.5c-0.2,0-0.3,0-0.5,0c-0.4,0-0.6-0.3-0.6-0.7c0-0.3,0.3-0.6,0.6-0.6
			c0.2,0,0.3,0,0.5,0c7.2,0,14.5,0,21.7,0c0.2,0,0.4,0,0.6,0c0.4,0.1,0.6,0.4,0.5,0.8c-0.1,0.3-0.3,0.5-0.5,0.5c-0.2,0-0.4,0-0.5,0
			L17.3,35.6z"
        />
        <path
          id="Tracciato_1060"
          class="st0"
          d="M17.3,38.7c2.6,0,5.2,0,7.8,0c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.4,0.4,0.6
			c-0.1,0.2-0.2,0.5-0.4,0.6C25.4,40.1,25.2,40,25,40c-5.1,0-10.2,0-15.4,0c-0.1,0-0.2,0-0.3,0c-0.5,0-0.8-0.3-0.8-0.7
			c0-0.4,0.4-0.7,0.7-0.6c0,0,0,0,0,0C12,38.7,14.7,38.7,17.3,38.7z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped></style>
