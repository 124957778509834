<script setup lang="ts">
const props = defineProps({
  class: { type: String, required: false, default: undefined },
  size: { type: String, required: false, default: "18px" },
});
</script>

<template>
  <!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.class"
    :width="size"
    :height="size"
    viewBox="0 0 60 60"
    style="enable-background: new 0 0 60 60"
    xml:space="preserve"
    aria-hidden="true"
  >
    <g id="Raggruppa_8587" transform="translate(-5025.495 -5397.257)">
      <g id="Raggruppa_8547" transform="translate(5033.039 5408.355)">
        <g id="Raggruppa_8545" transform="translate(16.22 0)">
          <path
            id="Tracciato_7972"
            class="st0"
            d="M0,5V4.3c0-0.1,0-0.1,0-0.2c0.1-1.3,0.7-2.5,1.8-3.3c1-0.8,2.3-1,3.6-0.7
				C6.3,0.4,7.1,1,7.6,1.8C7.8,2,8,2.3,8.1,2.5c0-0.1,0.1-0.1,0.1-0.2C8.5,2,8.7,1.6,9,1.3c1.1-1.2,2.8-1.6,4.3-1.1
				c1.4,0.5,2.5,1.6,2.8,3.1c0.4,1.5,0.1,3.1-0.8,4.4c-0.6,0.8-1.2,1.6-2,2.3c-1.6,1.4-3.2,2.8-4.8,4.2c-0.2,0.2-0.5,0.2-0.7,0.1
				c0,0,0,0-0.1-0.1c-1.3-1.1-2.5-2.2-3.8-3.3c-1-0.8-1.9-1.7-2.7-2.7C0.8,7.5,0.4,6.8,0.2,6C0.1,5.6,0.1,5.3,0,5z"
          />
        </g>
        <g id="Raggruppa_8546" transform="translate(0 16.973)">
          <path
            id="Tracciato_7973"
            class="st1"
            d="M1.4,9.3c1.4,0,2.8,0,4.1,0c0.3,0,0.5-0.1,0.7-0.2c1.1-0.6,2.1-1.3,3.1-2
				c0.5-0.3,0.7-0.7,0.4-1.1S9.2,5.6,8.8,5.9c-1,0.6-2,1.2-3,1.8C5.6,7.8,5.4,7.9,5.1,7.9c-1.4,0-2.8,0-4.1,0c-0.8,0-1,0.2-1,1
				c0,2.9,0,5.7,0,8.6c0,0.9,0.2,1,1,1c2.2,0,4.3,0,6.5,0.2c3,0.2,5.9,0.5,8.9,0.8c1.9,0.2,3.7,0.4,5.6,0.5c2.3,0.1,4.6-0.3,6.7-1.2
				c2.9-1.2,5.6-2.6,8.3-4.2c0.1,0,0.2-0.1,0.2-0.2c0.3-0.3,0.3-0.7,0-1c-0.2-0.2-0.5-0.2-0.7-0.2c-0.1,0.1-0.3,0.1-0.4,0.2
				c-2.5,1.2-4.9,2.6-7.4,3.7c-2.1,1-4.4,1.5-6.7,1.4c-2.9-0.2-5.8-0.5-8.7-0.8C9.6,17.4,5.8,17.2,2,17.2c-0.2,0-0.4,0-0.6,0
				L1.4,9.3z"
          />
          <path
            id="Tracciato_7974"
            class="st2"
            d="M47.6,7.7c-0.3,1-1,1.8-2,2.2c-2,1-3.9,2.1-5.8,3.2c-0.1,0.1-0.3,0.1-0.4,0.2
				c-0.4,0.1-0.8,0-0.9-0.4c-0.1-0.3,0-0.6,0.3-0.8c0.3-0.2,0.6-0.4,0.9-0.5c1.9-1,3.8-2.1,5.7-3.1c0.5-0.2,0.8-0.7,0.8-1.2
				c0-0.7-0.5-1.3-1.2-1.3c-0.3,0-0.5,0.1-0.8,0.2c-1.9,1-3.8,2.1-5.7,3.2c-4.5,2.6-9.7,3.7-14.8,3c-1.4-0.2-2.9-0.3-4.3-0.5
				c-0.1,0-0.2,0-0.4-0.1c-0.3,0-0.6-0.3-0.6-0.7c0-0.4,0.2-0.7,0.6-0.7c0,0,0,0,0,0c0.9-0.1,1.7-0.2,2.6-0.3l9.1-1.1
				c0.5,0,1-0.3,1.1-0.8c0.2-0.4,0.1-0.9-0.2-1.2c-0.3-0.3-0.7-0.4-1.1-0.4c-2.9,0-5.7,0-8.6,0c-0.4,0-0.7-0.1-1.1-0.2
				c-2.7-1-5.7-1.1-8.4-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0.1-0.8-0.1-0.9-0.5c-0.1-0.4,0.1-0.8,0.5-0.9c0,0,0,0,0.1,0
				c1.5-0.5,3.1-0.7,4.7-0.6C18,4,19.6,4.3,21.1,4.8c0.2,0.1,0.4,0,0.6-0.1c2.7-1.4,5.5-2.8,8.2-4.3c1.2-0.8,2.8-0.6,3.6,0.6
				c0.3,0.4,0.4,0.8,0.5,1.2c0.4-0.2,0.7-0.4,1.1-0.6c1.1-0.7,2.6-0.4,3.3,0.7c0.2,0.3,0.3,0.6,0.4,1c0,0.1,0,0.1,0.1,0.2
				c0.5-0.3,1-0.5,1.4-0.8c1.1-0.7,2.6-0.4,3.3,0.7c0.2,0.3,0.3,0.6,0.4,1c0,0,0,0.1,0,0c0.5,0,1,0,1.5,0c1,0.1,1.8,0.8,2.1,1.7
				c0.1,0.1,0.1,0.3,0.2,0.4L47.6,7.7z M25.2,10.9C25.3,11,25.3,11,25.2,10.9c0.2,0.1,0.4,0.1,0.5,0.1c2.2,0.1,4.4-0.1,6.5-0.6
				c2.4-0.7,4.7-1.7,6.9-3C40,6.9,41,6.3,42,5.8c0.4-0.2,0.6-0.7,0.5-1.1c-0.1-0.4-0.4-0.7-0.8-0.8c-0.3,0-0.6,0.1-0.8,0.2
				c-2.4,1.2-4.8,2.5-7.1,3.8c-0.2,0.1-0.4,0.3-0.4,0.5c-0.3,1-1.1,1.7-2.1,1.8C29.1,10.5,27.2,10.7,25.2,10.9z M24.3,5
				c0.1,0,0.2,0,0.3,0.1c1.4,0,2.7,0,4.1,0c0.2,0,0.3-0.1,0.5-0.1c0.9-0.5,1.7-0.9,2.6-1.4c0.2-0.1,0.4-0.2,0.5-0.4
				c0.3-0.5,0.2-1.1-0.2-1.5c-0.3-0.2-0.8-0.3-1.2,0c-1.4,0.7-2.7,1.4-4.1,2.1L24.3,5z M33.2,6.5c1.1-0.6,2.2-1.2,3.4-1.8
				c0.5-0.2,0.8-0.7,0.6-1.3c-0.2-0.5-0.7-0.8-1.3-0.6c-0.1,0-0.2,0.1-0.3,0.2c-1.2,0.7-2.4,1.3-3.7,2c-0.1,0.1-0.2,0.1-0.2,0.1
				L33.2,6.5z"
          />
          <path
            id="Tracciato_7975"
            class="st2"
            d="M1.4,9.3v7.8c0.2,0,0.4,0,0.6,0c3.8,0,7.6,0.2,11.3,0.7c2.9,0.2,5.8,0.6,8.7,0.8
				c2.3,0.1,4.6-0.4,6.7-1.4c2.5-1.2,4.9-2.5,7.4-3.7c0.1-0.1,0.3-0.1,0.4-0.2c0.4-0.1,0.7,0.1,0.9,0.4c0.1,0.2,0,0.5-0.2,0.7
				c-0.1,0.1-0.1,0.1-0.2,0.2c-2.7,1.6-5.4,3-8.3,4.2c-2.1,0.9-4.4,1.3-6.7,1.2c-1.9-0.1-3.7-0.3-5.6-0.5c-3-0.3-5.9-0.6-8.9-0.8
				c-2.2-0.1-4.3-0.1-6.5-0.2c-0.8,0-1-0.2-1-1c0-2.9,0-5.7,0-8.6c0-0.8,0.2-1,1-1c1.4,0,2.8,0,4.1,0c0.2,0,0.5-0.1,0.7-0.2
				c1-0.6,2-1.2,3-1.8C9.2,5.6,9.6,5.7,9.8,6s0.1,0.7-0.4,1.1c-1,0.7-2.1,1.3-3.1,2C6.1,9.2,5.8,9.3,5.5,9.3
				C4.2,9.3,2.8,9.3,1.4,9.3z"
          />
        </g>
        <path
          id="Tracciato_7976"
          class="st3"
          d="M22.8,7.1c-0.6-0.9-0.5-2.1,0.4-2.7c0.7-0.6,1.8-0.5,2.5,0.1c0.8,0.7,0.9,1.8,0.3,2.6
			C26,7.2,26,7.2,26.1,7.3c0.7,0.5,1.1,1.4,1.2,2.2c0,0.4-0.3,0.8-0.8,0.8c0,0,0,0-0.1,0c-1.4,0-2.8,0-4.2,0h0
			c-0.5,0-0.8-0.4-0.8-0.9c0,0,0-0.1,0-0.1C21.5,8.5,22,7.7,22.8,7.1z M24.3,9.7c0.7,0,1.3,0,2,0c0.2,0,0.2-0.1,0.2-0.2
			c-0.1-0.8-0.6-1.5-1.3-1.8c-0.4-0.2-0.4-0.4-0.1-0.7c0.5-0.4,0.6-1.2,0.1-1.7C24.8,4.6,24,4.6,23.5,5S23,6.2,23.4,6.7
			c0,0,0.1,0.1,0.1,0.1c0.3,0.3,0.3,0.5-0.1,0.7c-0.7,0.3-1.2,1-1.3,1.8c0,0.2,0,0.2,0.2,0.2C23,9.7,23.7,9.7,24.3,9.7L24.3,9.7z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: none;
  stroke: #363636;
}
.st1 {
  fill: #ffffff;
}
.st2 {
  fill: #363636;
}
.st3 {
  fill: #363636;
  stroke: #363636;
  stroke-width: 0.3;
}
</style>
