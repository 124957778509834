<script setup lang="ts">
const props = defineProps({
  class: { type: String, required: false, default: undefined },
  size: { type: String, required: false, default: "18px" },
});
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="props.class"
    :width="props.size"
    :height="props.size"
    viewBox="0 0 90 90"
    xml:space="preserve"
    aria-hidden="true"
  >
    <g id="Raggruppa_8308" transform="translate(-1185 -136)">
      <g id="Raggruppa_1873" transform="translate(1201.651 160.301)">
        <g id="Raggruppa_1872" transform="translate(0 7.672)">
          <path
            id="Tracciato_1049"
            class="st0"
            d="M0,3.4c0.1-0.3,0.2-0.6,0.3-0.9C0.9,1,2.5,0,4.1,0c2.7,0,5.5,0,8.2,0
				c5.5,0,10.9,0,16.4,0c0.2,0,0.4,0,0.6,0.1c0.4,0.1,0.6,0.4,0.6,0.8c0,0.4-0.3,0.7-0.7,0.7c-0.2,0-0.4,0-0.6,0H4.4
				C3,1.4,1.8,2.4,1.6,3.7c0,0.2,0,0.4,0,0.7c0,8.3,0,16.7,0,25c-0.2,1.3,0.8,2.6,2.1,2.7c0.2,0,0.4,0,0.6,0h39.7
				c1.4,0.2,2.6-0.8,2.8-2.1c0-0.2,0-0.4,0-0.7c0-4.8,0-9.7,0-14.5c0-0.2,0-0.4,0-0.5c0.1-0.4,0.5-0.7,0.9-0.7
				c0.3,0.1,0.6,0.3,0.7,0.7c0,0.2,0,0.3,0,0.5c0,4.9,0,9.8,0,14.6c0.2,2.2-1.5,4.2-3.8,4.3c-0.2,0-0.4,0-0.6,0
				c-13.2,0-26.4,0-39.7,0c-2.1,0.2-4-1.2-4.4-3.3c0,0,0-0.1-0.1-0.1L0,3.4z"
          />
          <path
            id="Tracciato_1050"
            class="st0"
            d="M4.8,13.6c0-1.6,0-3.1,0-4.7C4.8,6.7,6.5,4.9,8.7,4.8c0.1,0,0.2,0,0.2,0
				c3.1,0,6.2,0,9.4,0c2.2-0.1,4.1,1.6,4.1,3.9c0,0.1,0,0.1,0,0.2c0,3.2,0,6.4,0,9.6c0,2.2-1.8,4-4.1,4c0,0,0,0,0,0
				c-3.1,0-6.2,0-9.4,0c-2.2,0.1-4.1-1.7-4.2-3.9c0-0.1,0-0.2,0-0.3C4.8,16.7,4.8,15.2,4.8,13.6z M19.9,20.4c0.6-0.4,1-1.1,1-1.9
				c0-3.2,0-6.4,0-9.6c0-1.3-1-2.4-2.3-2.4c0,0,0,0-0.1,0c-3.3,0-6.5,0-9.8,0c-1.3,0-2.3,1.1-2.3,2.3c0,3.3,0,6.5,0,9.8
				c0,0.7,0.4,1.4,1,1.8c0.6-1.7,1.8-3.1,3.4-3.9c-2.1-1.6-2.4-4.6-0.8-6.7c0.1-0.1,0.2-0.2,0.3-0.4c1.8-1.8,4.6-1.8,6.5-0.1
				c0.9,0.8,1.4,1.9,1.5,3.1c0.1,1.6-0.6,3.1-1.8,4C18.6,17.8,19.1,18.4,19.9,20.4L19.9,20.4z M10.6,12.8c0,1.7,1.4,3.1,3.1,3
				c1.7,0,3.1-1.4,3-3.1c0-1.7-1.4-3.1-3.1-3c0,0,0,0,0,0C11.9,9.7,10.6,11.1,10.6,12.8L10.6,12.8z M18.3,20.9
				c-0.3-1.9-2.6-3.5-4.8-3.4c-2.1,0.1-4.2,1.8-4.4,3.4H18.3z"
          />
          <path
            id="Tracciato_1052"
            class="st0"
            d="M24.2,24.1h18.1c0.2,0,0.4,0,0.5,0c0.4,0,0.8,0.4,0.7,0.8c0,0.4-0.3,0.7-0.6,0.7
				c-0.2,0-0.4,0-0.5,0c-12.1,0-24.3,0-36.4,0c-0.2,0-0.4,0-0.5,0c-0.4-0.1-0.7-0.5-0.6-0.9c0.1-0.4,0.4-0.6,0.7-0.6
				c0.2,0,0.4,0,0.5,0H24.2z"
          />
          <path
            id="Tracciato_1053"
            class="st0"
            d="M24.2,27.3h18.1c0.2,0,0.4,0,0.5,0c0.4,0,0.8,0.4,0.7,0.8c0,0.4-0.3,0.7-0.6,0.7
				c-0.2,0-0.4,0-0.5,0c-12.1,0-24.3,0-36.4,0c-0.2,0-0.4,0-0.5,0C5,28.8,4.8,28.4,4.8,28c0.1-0.4,0.4-0.6,0.7-0.6
				c0.2,0,0.4,0,0.5,0H24.2z"
          />
          <path
            id="Tracciato_1054"
            class="st0"
            d="M34.7,17.7c2.6,0,5.2,0,7.9,0c0.3,0,0.6,0.1,0.9,0.3c0.3,0.3,0.3,0.8-0.1,1.1
				c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0-0.4,0.1-0.6,0.1c-5.2,0-10.3,0-15.5,0c-0.1,0-0.2,0-0.3,0c-0.6,0-0.9-0.3-0.9-0.8
				s0.4-0.8,0.9-0.8C29.4,17.7,32,17.7,34.7,17.7z"
          />
          <path
            id="Tracciato_1055"
            class="st0"
            d="M34.7,20.9c2.6,0,5.3,0,7.9,0c0.3,0,0.6,0.1,0.8,0.3c0.3,0.3,0.3,0.8,0,1.1
				c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0.1-0.4,0.1-0.6,0.1c-5.2,0-10.4,0-15.6,0c-0.1,0-0.2,0-0.3,0c-0.5,0-0.8-0.3-0.9-0.8
				c0,0,0,0,0,0c0-0.4,0.4-0.8,0.8-0.8c0,0,0.1,0,0.1,0c2.4,0,4.7,0,7.1,0L34.7,20.9z"
          />
        </g>
        <path
          id="Tracciato_1061"
          class="st0"
          d="M33.5,23.4c-1.2-0.3-1.9-1.4-1.7-2.6c0-0.7-0.1-1.4-0.4-2.1c-0.6-1.8-0.9-3.6-0.7-5.5
			c0.1-1.2,0.4-2.5,0.6-3.7c-1-0.7-1.6-1.8-1.7-3c-0.1-0.8,0.5-1.6,1.3-1.7c0.2,0,0.4,0,0.6,0c0.7,0.2,1.3,0.4,2,0.6
			c0.3-0.6,0.7-1.2,1.1-1.8c0.2-0.3,0.5-0.6,0.7-1c1-1.4,2.6-2.3,4.4-2.5c2.9-0.4,5.9-0.3,8.7,0.2c1.4,0.3,2.5,1.1,3.3,2.3
			c0.7,0.9,1.2,1.8,1.9,2.7C54,5.3,54.5,5.1,54.9,5c0.9-0.4,2-0.1,2.4,0.8c0,0,0,0.1,0.1,0.1v1c-0.3,1.1-0.9,2-1.8,2.6
			c0.5,1.3,0.7,2.8,0.7,4.2c0.1,1.7-0.1,3.4-0.7,5c-0.3,0.6-0.4,1.4-0.4,2.1c0.2,1.2-0.5,2.3-1.7,2.7h-3.1c-1.2-0.4-1.9-1.6-1.6-2.9
			c-3.5,1-7.2,1-10.7,0c0.3,1.3-0.4,2.5-1.7,2.9L33.5,23.4z M33.4,10.6l0.6,0.1c1.1,0.1,2.2,0.5,3.2,1.1c0.6,0.5,1,1.2,1,2
			c0,0.6-0.4,1-1,1.1c-0.5,0-1-0.3-1.1-0.9c0-0.2-0.2-0.4-0.3-0.6c-0.9-0.5-1.9-0.7-2.8-0.7c-0.1,1.6,0,3.3,0.4,4.9
			c0,0.3,0.3,0.4,0.5,0.4c0,0,0,0,0,0c0.7,0,1.4,0,2.1,0c0.8,0,1.6,0.1,2.5,0.3c2.8,0.8,5.7,0.9,8.6,0.3c2-0.5,4.1-0.8,6.2-0.7
			c0.2,0.1,0.5-0.1,0.5-0.3c0,0,0,0,0,0c0.4-1.6,0.5-3.2,0.4-4.8c0-0.1-0.1-0.2-0.1-0.3c-0.8,0.2-1.5,0.3-2.3,0.5
			c-0.4,0.1-0.8,0.4-0.8,0.9c-0.1,0.5-0.6,0.9-1.1,0.8c-0.5,0-1-0.5-1-1c-0.1-0.8,0.3-1.5,0.9-1.9c0.7-0.4,1.4-0.7,2.1-1
			c0.6-0.2,1.2-0.3,1.8-0.3c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.4-0.3-0.6-0.8-0.5c-1.7,0.3-3.4,0.5-5.1,0.7c-1.6,0.2-3.3,0.3-4.9,0.3
			c-2.7-0.2-5.4-0.6-8.1-0.9c-0.9-0.1-0.9-0.1-1.2,0.7C33.4,10.5,33.4,10.6,33.4,10.6L33.4,10.6z M52,7.5c0-0.1,0-0.1-0.1-0.2
			c-0.6-1.6-1.5-3-2.7-4.1c-0.4-0.3-0.8-0.5-1.3-0.6C45.4,2,42.7,2,40.1,2.4c-0.8,0-1.6,0.3-2.2,0.7c-1.2,1.2-2.2,2.6-2.8,4.2
			c0,0.1,0,0.1,0,0.2C40.7,8.7,46.4,8.7,52,7.5L52,7.5z M36.1,20.2H34v1h2.1V20.2z M51,20.2v1h2.1v-1H51z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped></style>
