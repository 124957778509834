import { default as indexmyoIE8bIUQMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/[[step]]/index.vue?macro=true";
import { default as CreditSwitcheri02bEVr78RMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/components/CreditSwitcher.vue?macro=true";
import { default as FlashMessageAccordion4Tjx2exyIMMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/components/FlashMessageAccordion.vue?macro=true";
import { default as HomezmTcC7ABCdMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/components/Home.vue?macro=true";
import { default as RatePowerCreditbmKa3FHiHxMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/components/RatePowerCredit.vue?macro=true";
import { default as RatePowerCreditUpgradeqT98geInIaMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/components/RatePowerCreditUpgrade.vue?macro=true";
import { default as components10krfzc4QXMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/config/components.ts?macro=true";
import { default as eventsS7aDoTzH1zMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/config/events.ts?macro=true";
import { default as handlersYMdqLKqqOAMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/config/handlers.ts?macro=true";
import { default as mappingCgepcscom8Meta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/config/mapping.ts?macro=true";
import { default as propsQxvbf7Q4oQMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/config/props.ts?macro=true";
import { default as debugHus771ssPHMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/data/debug.ts?macro=true";
import { default as devfKBejiJvGDMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/data/dev.ts?macro=true";
import { default as ppAmHhPQHOcAMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/data/pp.ts?macro=true";
import { default as routesZUdNzhCyfqMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/routes.ts?macro=true";
import { default as indexenvmnppH7EMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/utils/index.ts?macro=true";
import { default as priceskEtmYLP9YvMeta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/utils/prices.ts?macro=true";
import { default as redirectsNqNFvLuef6Meta } from "/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/utils/redirects.ts?macro=true";
export default [
  {
    name: "step",
    path: "/:step?",
    meta: indexmyoIE8bIUQMeta || {},
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/[[step]]/index.vue")
  },
  {
    name: "components-CreditSwitcher",
    path: "/components/CreditSwitcher",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/components/CreditSwitcher.vue")
  },
  {
    name: "components-FlashMessageAccordion",
    path: "/components/FlashMessageAccordion",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/components/FlashMessageAccordion.vue")
  },
  {
    name: "components-Home",
    path: "/components/Home",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/components/Home.vue")
  },
  {
    name: "components-RatePowerCredit",
    path: "/components/RatePowerCredit",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/components/RatePowerCredit.vue")
  },
  {
    name: "components-RatePowerCreditUpgrade",
    path: "/components/RatePowerCreditUpgrade",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/components/RatePowerCreditUpgrade.vue")
  },
  {
    name: "config-components",
    path: "/config/components",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/config/components.ts")
  },
  {
    name: "config-events",
    path: "/config/events",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/config/events.ts")
  },
  {
    name: "config-handlers",
    path: "/config/handlers",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/config/handlers.ts")
  },
  {
    name: "config-mapping",
    path: "/config/mapping",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/config/mapping.ts")
  },
  {
    name: "config-props",
    path: "/config/props",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/config/props.ts")
  },
  {
    name: "data-debug",
    path: "/data/debug",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/data/debug.ts")
  },
  {
    name: "data-dev",
    path: "/data/dev",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/data/dev.ts")
  },
  {
    name: "data-pp",
    path: "/data/pp",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/data/pp.ts")
  },
  {
    name: "routes",
    path: "/routes",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/routes.ts")
  },
  {
    name: "utils",
    path: "/utils",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/utils/index.ts")
  },
  {
    name: "utils-prices",
    path: "/utils/prices",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/utils/prices.ts")
  },
  {
    name: "utils-redirects",
    path: "/utils/redirects",
    component: () => import("/Users/cofidisdigital/Documents/repositories/DEPLOY/credito-nuxt3/themes/credito/utils/redirects.ts")
  },
  {
    path: "/auto",
    name: "auto"
  },
  {
    path: "/consolidamento",
    name: "consolidamento"
  }
]