import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin((_nuxtApp) => {
  _nuxtApp.hook("app:mounted", () => {
    const setCursorToEnd = (event: Event) => {
      const input = event.target as HTMLInputElement;

      if (
        input.tagName?.toLowerCase() === "input" &&
        input?.type === "text" &&
        Boolean(input.dataset?.mask ?? false)
      ) {
        const length = input.value.length;
        input.setSelectionRange(length, length);
      }
    };
    const avoidCursorMove = (event: Event) => {
      if (event instanceof KeyboardEvent) {
        const target = event.target as HTMLElement;
        if (
          ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"].includes(
            event.key,
          ) &&
          target.tagName?.toLowerCase() !== "select"
        ) {
          event.preventDefault();
        }
      }
    };

    window.addEventListener("keydown", avoidCursorMove);
    window.addEventListener("click", setCursorToEnd);
    window.addEventListener("focus", setCursorToEnd, true);
  });
});
