import { promoCredit } from "~/utils/promo";

export default {
  rules: {},
  payload: {
    hasError: () => {
      return false;
    },
    pendingOrder: () => {
      return false;
    },

    orderID: () => {
      return getStateValue("orderID", null);
    },
    importo: () => {
      const promoUpgrated = getStateValue("promoUpgraded");
      const choosenCredit = getStateValue("choosenCredit");
      const importoUpdated = promoCredit() !== choosenCredit;

      return promoUpgrated && importoUpdated
        ? choosenCredit
        : getStateValue("credit", null);
    },

    rate: () => {
      return getStateValue("rate", null);
    },
    name: () => {
      return getStateValue("name", null);
    },
    email: () => {
      return getStateValue("email", null);
    },
    surname: () => {
      return getStateValue("surname", null);
    },
    cellphone: () => {
      return getStateValue("cellphone", null);
    },
    fiscal_code: () => {
      return getStateValue("taxCode", null);
    },

    stay_from: () => {
      return getStateValue("stayFrom", null);
    },
    stay_to: () => {
      return getStateValue("stayTo", null);
    },
    marital_status: () => {
      return getStateValue("maritalStatus", null);
    },
    citizenship: () => {
      return getStateValue("citizenship");
    },

    financial_problems: () => {
      return getStateValue("financialProblems", null);
    },

    dwelling_type: () => {
      return getStateValue("housingType", null);
    },
    monthly_rent: () => {
      return getStateValue("monthlyRent", null);
    },

    address: () => {
      return getStateValue("address", null);
    },
    address_number: () => {
      return getStateValue("addressNumber", null);
    },
    address_cap: () => {
      return getStateValue("addressCap", null);
    },
    address_multicap: () => {
      return getStateValue("addressMulticap", []);
    },
    address_city: () => {
      return getStateValue("addressCity", null);
    },
    resident_since: () => {
      return getStateValue("residenceSince", null);
    },
    same_addresses: () => {
      return getStateValue("sameAddresses", null);
    },
    address2: () => {
      return getStateValue("address2", null);
    },
    address_number2: () => {
      return getStateValue("addressNumber2", null);
    },
    address_cap2: () => {
      return getStateValue("addressCap2", null);
    },
    address_multicap2: () => {
      return [];
    },
    address_city2: () => {
      return getStateValue("addressCity2", null);
    },
    identity_type: () => {
      return getStateValue("identityType", null);
    },
    document_number: () => {
      return getStateValue("documentNumber", null);
    },
    document_city: () => {
      return getStateValue("documentCity", null);
    },
    document_expire: () => {
      return getStateValue("documentExpire", null);
    },
    document_release: () => {
      return getStateValue("documentRelease", null);
    },

    employment_type: () => {
      return getStateValue("employmentType", null);
    },
    employment_type_text: () => {
      return getStateValue("employmentTypeText", null);
    },
    employment_sector: () => {
      return getStateValue("employmentSector", null);
    },
    employment_sector_text: () => {
      return getStateValue("employmentSectorText", null);
    },
    occupation: () => {
      return getStateValue("occupation", null);
    },
    occupation_text: () => {
      return getStateValue("occupationText", null);
    },
    monthly_payment: () => {
      return getStateValue("monthlyPayment", null);
    },
    monthly_payment_text: () => {
      return getStateValue("monthlyPaymentText", null);
    },
    monthly_income: () => {
      return getStateValue("monthlyIncome", null);
    },
    employed_since: () => {
      return getStateValue("employedSince", null);
    },
    employed_to: () => {
      return getStateValue("employedTo", null);
    },
    hiring_type: () => {
      return getStateValue("hiringType", null);
    },
    hiring_type_text: () => {
      return getStateValue("hiringTypeText", null);
    },

    family_member_count: () => {
      return getStateValue("familyMemberCount", null);
    },
    employed_family_member_count: () => {
      return getStateValue("employedFamilyMemberCount", null);
    },

    family_income: () => {
      return getStateValue("familyIncome", null);
    },
    family_income_activity: () => {
      return getStateValue("familyIncomeActivity", null);
    },

    employer_name: () => {
      return getStateValue("employerName", null);
    },
    employer_address: () => {
      return getStateValue("employerAddress", null);
    },
    employer_street_number: () => {
      return getStateValue("employerStreetNumber", null);
    },
    employer_postal_code: () => {
      return getStateValue("employerPostalCode", null);
    },
    employer_multicap: () => {
      return getStateValue("employerMulticap", []);
    },
    employer_city: () => {
      return getStateValue("employerCity", null);
    },
    employer_phone: () => {
      return getStateValue("employerPhone", null);
    },
    cessione_quinto: () => {
      return getStateValue("employeeLoan", null);
    },

    insurance: () => {
      return getStateValue("insurance", null);
    },
    insurance_amount: () => {
      return getStateValue("insuranceAmount", null);
    },
    insurance_pack: () => {
      return getStateValue("insurancePack", null);
    },
    insurance_available: () => {
      return insuranceAvailable();
    },

    // club/club plus
    service: () => {
      return getStateValue("service", null);
    },
    service_amount: () => {
      return getStateValue("serviceAmount", null);
    },
    service_pack: () => {
      return getStateValue("servicePack", null);
    },
    service_available: () => {
      return getStateValue("serviceAvailable", null);
    },

    day_in_month: () => {
      return getStateValue("installmentDebitDay", null);
    },
    iban: () => {
      return getStateValue("iban", null);
    },

    consent_club5a: () => {
      return getStateValue("clubPlusConsent5a", null);
    },
    consent_club5b: () => {
      return getStateValue("clubPlusConsent5b", null);
    },
    consent_club5c: () => {
      return getStateValue("clubPlusConsent5c", null);
    },
    consent_club5d: () => {
      return getStateValue("clubPlusConsent5d", null);
    },

    privacy1: () => {
      return getStateValue("privacy1", null);
    },
    privacy2: () => {
      return getStateValue("privacy2", null);
    },
    privacy3: () => {
      return getStateValue("privacy3", null);
    },
    privacy4: () => {
      return getStateValue("privacy4", null);
    },
    politics: () => {
      return getStateValue("politicallyExposedPerson", null);
    },

    source: () => {
      return location.href.substring(0, location.href.lastIndexOf("/"));
    },
    // all crm landings
    /*exp_date: () => {
            return null
        },*/
  },
};
