import locale from "~/static/locale/it/inputs.json";
import CardId from "~/components/icons/ic/CardId.vue";
import Passport from "~/components/icons/ic/Passport.vue";
import DriversLicense from "~/components/icons/ic/DriversLicense.vue";
import Celibe from "~/components/icons/ms/Celibe.vue";
import Divorziato from "~/components/icons/ms/Divorziato.vue";
import Coniugato from "~/components/icons/ms/Coniugato.vue";
import Convivente from "~/components/icons/ms/Convivente.vue";
import Separato from "~/components/icons/ms/Separato.vue";
import Vedovo from "~/components/icons/ms/Vedovo.vue";

export const yesNoOptions = [
  { value: true, label: locale.yes },
  { value: false, label: locale.no },
];

export const okOption = [{ value: true, label: "OK" }];

export const housingTypeOptions = [
  { value: null, label: locale.housing_types.title },
  { value: 1, label: locale.housing_types.for_rent },
  { value: 2, label: locale.housing_types.with_parents_relatives },
  { value: 5, label: locale.housing_types.of_ownership },
];

export const debitDayOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "10" },
];

export const debitDayPowerOptions = [{ value: 1, label: "15" }];

export const identityDocOptions = [
  {
    value: 1,
    label: locale.identity_docs.identity_card,
    icon: CardId,
  },
  {
    value: 3,
    label: locale.identity_docs.passport,
    icon: Passport,
  },
  {
    value: 4,
    label: locale.identity_docs.drivers_license,
    icon: DriversLicense,
  },
];

export const familyIncomeActivities = [
  { value: 0, label: locale.family_income_activities.employment_type },
  { value: 2, label: locale.family_income_activities.employee },
  { value: 6, label: locale.family_income_activities.pensioner },
];

export const maritalStatusOptions = [
  { icon: Celibe, label: locale.marital_status.single, value: 1 },
  { icon: Divorziato, label: locale.marital_status.divorced, value: 2 },
  { icon: Coniugato, label: locale.marital_status.married, value: 3 },
  { icon: Convivente, label: locale.marital_status.cohabitant, value: 4 },
  { icon: Separato, label: locale.marital_status.separated, value: 5 },
  { icon: Vedovo, label: locale.marital_status.widower, value: 6 },
];
