import Validator from "validatorjs";
import en from "validatorjs/src/lang/en";
import { evaluateObjectFunctions } from "~/utils";

export default defineNuxtPlugin(() => {
  Validator.setMessages("en", en);

  Validator.register(
    "nullable",
    (value: string | number | boolean, requirement, attribute) => {
      return value === null || value === undefined || value === "";
    },
    "The {attribute} field can be empty or must be valid.",
  );

  return {
    provide: {
      validate: ({ payload, rules }: any) => {
        payload = evaluateObjectFunctions(payload);
        return new Validator(payload, rules);
      },
    },
  };
});
