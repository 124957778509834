<script setup lang="ts">
const props = defineProps({
  class: { type: String, required: false, default: undefined },
  size: { type: String, required: false, default: "18px" },
});
</script>

<template>
  <!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.class"
    :width="size"
    :height="size"
    viewBox="0 0 60 60"
    style="enable-background: new 0 0 60 60"
    xml:space="preserve"
    aria-hidden="true"
  >
    <g id="Raggruppa_8590" transform="translate(-939 -5359.521)">
      <g id="Raggruppa_8563" transform="translate(946.936 5367.936)">
        <g id="Raggruppa_8561" transform="translate(18.012 8.171)">
          <path
            id="Tracciato_7984"
            class="st0"
            d="M0,2.2V1.9c0,0,0-0.1,0-0.1c0-0.6,0.3-1.1,0.8-1.5c0.4-0.4,1-0.5,1.6-0.3
				c0.4,0.1,0.8,0.4,1,0.7C3.5,0.9,3.5,1,3.6,1.1c0,0,0-0.1,0.1-0.1C3.8,0.9,3.9,0.7,4,0.6C4.5,0,5.2-0.1,5.9,0.1
				C6.5,0.3,7,0.8,7.1,1.5c0.2,0.7,0,1.4-0.4,2c-0.3,0.4-0.6,0.7-0.9,1C5.2,5.1,4.5,5.7,3.8,6.3c-0.1,0.1-0.2,0.1-0.3,0c0,0,0,0,0,0
				c-0.6-0.5-1.1-1-1.7-1.4C1.3,4.5,0.9,4.1,0.6,3.6C0.3,3.3,0.2,3,0.1,2.6C0,2.5,0,2.4,0,2.2z"
          />
        </g>
        <g id="Raggruppa_8562" transform="translate(0)">
          <path
            id="Tracciato_7985"
            class="st0"
            d="M23.8,43.2c-0.3-0.2-0.5-0.5-0.4-0.9c0-2.3,0.1-4.6,0-6.8c-0.2-1.4,0.8-2.6,2.2-2.8
				c0.1,0,0.3,0,0.4,0v-0.5c0-1.8,0-3.6,0-5.4c0-0.9,0.3-1.7,0.9-2.2c1.8-1.8,3.7-3.6,5.5-5.5c0.2-0.2,0.3-0.4,0.3-0.6
				c0-0.7,0-1.3,0-2c0-1.5,1.1-2.7,2.5-3c0.2,0,0.4-0.1,0.6-0.1c-0.1-0.9,0.1-1.8,0.7-2.6c0.6-0.7,1.5-1.2,2.4-1.2
				c0-0.4,0.1-0.8,0.1-1.3c0.3-1.6,1.8-2.7,3.4-2.5c0.5,0,0.9,0.5,0.9,1c0,0,0,0,0,0c0,1.7,0,3.5,0,5.2c0,0.3-0.2,0.7-0.6,0.7
				c0,0,0,0-0.1,0c-0.4,0-0.6-0.3-0.6-0.7c0-1.5,0-3,0-4.5V7c-1,0-1.8,0.8-1.9,1.8c0,0,0,0.1,0,0.1c0,1.7,0,3.4,0,5.1
				c0,1.1,0,2.2,0,3.2c0,0.6-0.2,1.1-0.6,1.5c-2.4,2.4-4.9,4.9-7.3,7.3c-0.4,0.4-0.7,0.4-1,0.1s-0.2-0.6,0.1-1
				c1.3-1.3,2.6-2.6,3.9-3.9c0.9-0.7,1.2-2,0.5-3c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-2.5,2.5-5,5-7.5,7.5
				c-0.3,0.3-0.5,0.7-0.5,1.2c0,1.9,0,3.7,0,5.6c0,0.1,0,0.2,0,0.4c0.2,0,0.3,0,0.4,0c2.3,0,4.6,0,7,0c0.2,0,0.4-0.1,0.6-0.3
				c2.2-3.6,4.4-7.2,6.6-10.9c0.2-0.3,0.3-0.6,0.3-1c0-1.8,0-3.6,0-5.4c0-0.6,0.5-0.9,0.9-0.7c0.2,0.1,0.3,0.4,0.4,0.6
				c0,1.9,0,3.9,0,5.8c0,0.5-0.2,0.9-0.5,1.3c-2,3.3-4,6.7-6,10c-0.1,0.2-0.2,0.3-0.3,0.5c0,0,0.1,0.1,0.1,0.1
				c1.2,0.2,2,1.3,1.9,2.5c0,2.4,0,4.8,0,7.1c0,0.4-0.1,0.7-0.5,0.9l-0.5,0c-0.3-0.2-0.4-0.6-0.4-0.9c0-2.3,0-4.7,0-7
				c0-1.1-0.5-1.4-1.7-1.3c-3.1,0.2-6.2,0.2-9.2,0c-1.2-0.1-1.6,0.3-1.6,1.3c0,2.3,0,4.7,0,7c0,0.3-0.1,0.7-0.4,0.9L23.8,43.2z
				 M38.8,10.8c-1,0-1.9,0.9-1.9,1.9c0,0,0,0,0,0c0,1.5,0,2.9,0,4.4c0.1,0.7,0.2,1.3,0.3,2c0.5-0.5,1-0.9,1.3-1.4
				c0.1-0.2,0.2-0.4,0.2-0.6c0-1.1,0-2.2,0-3.2C38.8,12.8,38.8,11.8,38.8,10.8L38.8,10.8z M35.7,14.6c-1.3,0-2.1,1.2-1.8,2.8
				c0.6-0.4,0.9-1.2,1.8-1.2V14.6z"
          />
          <path
            id="Tracciato_7986"
            class="st0"
            d="M19,43.2c-0.3-0.2-0.5-0.5-0.4-0.9c0-2.4,0-4.7,0-7.1c0-0.9-0.4-1.3-1.3-1.3
				c-1.6,0-3.2,0-4.8,0c-0.2,0-0.5-0.1-0.6-0.3c-0.1-0.2-0.1-0.4-0.1-0.6c0.1-0.2,0.4-0.3,0.6-0.4c1,0,2.1,0,3.1,0H16v-0.5
				c0-1.9,0-3.8,0-5.7c0-0.4-0.1-0.7-0.4-1c-2.6-2.6-5.1-5.2-7.7-7.7c0,0-0.1-0.1-0.2-0.2c-0.2,0.4-0.4,0.8-0.5,1.2
				c-0.2,0.7,0,1.6,0.6,2.1c1.4,1.4,2.7,2.7,4.1,4.1c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.2,0.6-0.1,0.8c0,0,0,0,0,0
				c-0.1,0.1-0.1,0.1-0.2,0.1c-0.4,0.1-0.6-0.1-0.8-0.3c-2.4-2.4-4.8-4.8-7.1-7.1c-0.5-0.4-0.7-1.1-0.7-1.7c0-2.7,0-5.4,0-8.1
				c0-1-0.8-1.9-1.8-2c0,0,0,0-0.1,0v0.4c0,4.3,0,8.7,0,13c0,0.4,0.1,0.7,0.3,1c2.2,3.6,4.4,7.2,6.5,10.8c0.1,0.3,0.4,0.4,0.6,0.4
				c0.2,0,0.5,0,0.7,0c0.3,0,0.6,0.2,0.7,0.6c0,0,0,0,0,0.1c0,0.3-0.3,0.6-0.6,0.6c0,0,0,0-0.1,0c-0.7,0-1.4,0-2.1,0
				c-0.6-0.1-1.2,0.3-1.2,0.9c0,0.1,0,0.2,0,0.3c0,2.4,0,4.7,0,7.1c0,0.4-0.1,0.7-0.4,0.9H5.2C5,43,4.8,42.7,4.8,42.4
				c0-2.5,0-5.1,0-7.6c0-1,0.6-1.8,1.6-2.1c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.2-0.2-0.3-0.3-0.5c-1.9-3.1-3.7-6.2-5.6-9.3
				c-0.3-0.6-0.6-1.2-0.9-1.7V6.3c0.3-0.6,1-0.8,1.6-0.6c1.6,0.2,2.8,1.6,2.7,3.2c0,0.2,0,0.4,0,0.6c0.9,0,1.8,0.5,2.4,1.2
				c0.5,0.7,0.8,1.6,0.7,2.5c0.6,0.2,1.1,0.4,1.6,0.6c0.9,0.5,1.5,1.5,1.5,2.6c0,0.7,0,1.3,0,2c0,0.3,0.1,0.5,0.3,0.7
				c1.8,1.8,3.7,3.7,5.5,5.5c0.6,0.5,0.9,1.3,0.9,2.1c0,1.8,0,3.7,0,5.5v0.5c0.2,0,0.5,0.1,0.7,0.1c1.1,0.2,1.9,1.1,1.9,2.2
				c0,2.5,0,5,0,7.5c0,0.3-0.1,0.6-0.4,0.7L19,43.2z M4.4,10.8c0,0.1,0,0.1,0,0.2c0,2.1,0,4.2,0,6.3c0,0.1,0,0.3,0.1,0.4
				c0.4,0.4,0.9,0.9,1.4,1.3c0.2-0.4,0.3-0.8,0.4-1.3c0-1.7,0-3.4,0-5.1C6.2,11.5,5.4,10.8,4.4,10.8L4.4,10.8z M9.4,17.4
				c0.1-1.8-0.5-2.8-1.8-2.8v1.6C8.4,16.1,8.7,17,9.4,17.4L9.4,17.4z"
          />
          <path
            id="Tracciato_7987"
            class="st0"
            d="M14.3,16.4V8.6c-0.3,0-0.6,0-0.9,0c-0.7-0.1-1.2-0.7-1.2-1.4c0-0.4,0-0.8,0-1.1
				c-0.1-1,0.5-2,1.5-2.4c2.2-1.1,4.5-2.1,6.7-3.3c0.8-0.4,1.7-0.4,2.5,0c2.2,1.1,4.4,2.2,6.6,3.2C30.5,4,31.1,5,31,6.1
				c0,0.3,0,0.6,0,0.9c0.1,0.8-0.5,1.5-1.4,1.6c-0.2,0-0.4,0-0.6-0.1l-0.2,0v7.9c0.3,0,0.6,0,0.9,0c0.5,0,0.7,0.2,0.8,0.6
				c0,0.4-0.3,0.6-0.8,0.6c-2.2,0-4.3,0-6.5,0h-9.7c-0.7,0-1-0.2-1-0.6c0-0.4,0.3-0.7,1-0.7L14.3,16.4z M27.6,16.4
				c0-0.1,0-0.2,0-0.2c0-2.7,0-5.4,0-8.1c0-0.3-0.2-0.4-0.3-0.4c-1.8-0.9-3.6-1.7-5.3-2.6c-0.2-0.1-0.4-0.1-0.6,0
				c-1.8,0.9-3.6,1.7-5.3,2.6c-0.2,0.1-0.4,0.3-0.3,0.5c0,2.6,0,5.2,0,7.8v0.4L27.6,16.4z M29.7,6.2C29.9,5.6,29.6,5,29,4.8
				c-2.4-1.1-4.7-2.3-7-3.4c-0.2-0.1-0.5-0.1-0.7,0c-2.1,1-4.2,2.1-6.3,3.1c-1.6,0.8-1.5,0.7-1.5,2.4c0,0.7,0,0.7,0.6,0.4
				c2.3-1.1,4.5-2.2,6.8-3.3c0.4-0.2,1-0.2,1.4,0c1.8,0.9,3.7,1.8,5.6,2.7l1.4,0.7c0.3,0.1,0.4,0.1,0.4-0.3
				C29.7,6.8,29.7,6.6,29.7,6.2L29.7,6.2z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: #363636;
}
</style>
